'use client';

import * as React from 'react';

type TabsContextValue = {
  onSelectTab: (index: number) => void;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  rootId: string;
  activeTabRef: HTMLButtonElement | null;
  setActiveTabRef: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
};

const defaultTabsContextValue: TabsContextValue = {
  onSelectTab: () => {},
  selectedIndex: 0,
  setSelectedIndex: () => {},
  rootId: '',
  activeTabRef: null,
  setActiveTabRef: () => {},
};

export const TabsContext = React.createContext<TabsContextValue>(defaultTabsContextValue);

export default function TabsProvider({
  children,
  defaultTabIndex,
}: {
  children: React.ReactNode;
  defaultTabIndex?: number;
}) {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultTabIndex ?? 0);
  const [activeTabRef, setActiveTabRef] = React.useState<HTMLButtonElement | null>(null);

  const rootId = React.useId();

  const onSelectTab = React.useCallback(
    (index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );
  return (
    <TabsContext.Provider
      value={{
        setSelectedIndex,
        selectedIndex,
        onSelectTab,
        rootId,
        activeTabRef,
        setActiveTabRef,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}
