'use client';
import useList, { useListActions } from '@haaretz/s-use-list';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import React from 'react';

import useTeasers from '../../hooks/useTeasers';
import lazyListLoader from '../../server-actions/lazyListLoader';

import type { MandatoryListProps, WithClientListProps } from '@haaretz/s-data-structure-types';

export interface LazyProps extends WithClientListProps<MandatoryListProps> {}

export default function LazyListClient({
  children: placeholder,
  ...props
}: React.PropsWithChildren<LazyProps>) {
  const { inView, obeservedElement } = useList();
  const { setIsShowing } = useListActions();
  const [list, setList] = React.useState<React.ReactNode>(undefined);
  const isLoaded = useLoadEvent();

  const { pageContentId } = props;
  const isLazyList = props.kind === 'lazy';
  const [, startLazyTransition] = React.useTransition();

  const shouldLoading = isLazyList && isLoaded && inView && !list;

  const teasersOnPage = useTeasers(shouldLoading ? 'live' : 'default');

  const handleLoadLazyList = React.useCallback(
    function handleLoadMore() {
      startLazyTransition(async () => {
        try {
          const result = await lazyListLoader({
            listStyle: props.listStyle,
            postCacheParams: 'sa-list-lazy',
            variables: {
              mainContentId: pageContentId,
              id: props.contentId,
              exclude: Array.from(teasersOnPage).toString(),
              page: 1,
            },
          });

          /* istanbul ignore next */
          if (result?.result) {
            console.table({
              title: 'LazyList',
              style: props.listStyle,
              contentId: props.contentId,
              result: result.result,
            });
          }

          if (result?.html) {
            setList(result.html);
          } else {
            setIsShowing(false);
            console.error(new Error('Personal list failed to load'));
          }
        } catch (error) {
          /* istanbul ignore next */
          setIsShowing(false);

          /* istanbul ignore next */
          console.error(error);
        }
      });
    },
    [props.listStyle, props.contentId, pageContentId, teasersOnPage, setIsShowing]
  );

  React.useEffect(() => {
    if (shouldLoading) {
      handleLoadLazyList();
    }
  }, [handleLoadLazyList, shouldLoading]);

  if (list) {
    return list;
  }

  return (
    <>
      {obeservedElement}
      {placeholder}
    </>
  );
}
