'use client';

import React from 'react';

import useArticleId from '@haaretz/s-atoms/articleId';

import type { TagPageFragment } from '@haaretz/s-fragments/TagPage';

export type TeaserOnPageAtomType = Pick<TagPageFragment, 'contentId' | 'name'>[] | null;

const TeaserOnPageContext = React.createContext<Set<string> | undefined>(undefined);
const TeaserOnPageSetter = React.createContext<
  ((value: string, action: 'add' | 'delete') => void) | undefined
>(undefined);

export function TeaserOnPageProvider({ children }: React.PropsWithChildren) {
  const [teaserOnPage, setTeaserOnPage] = React.useState<Set<string>>(new Set<string>());
  const articleId = useArticleId();

  React.useEffect(() => {
    if (articleId && !teaserOnPage.has(articleId)) {
      setTeaserOnPage(prev => new Set<string>([...prev, articleId]));
    }
  }, [articleId, teaserOnPage]);

  const teaserOnPageSetter = React.useCallback((value: string, action: 'add' | 'delete') => {
    if (value) {
      if (action === 'add') {
        setTeaserOnPage(prev => new Set<string>([...prev, value]));
      }
    }
  }, []);

  return (
    <TeaserOnPageContext.Provider value={teaserOnPage}>
      <TeaserOnPageSetter.Provider value={teaserOnPageSetter}>
        {children}
      </TeaserOnPageSetter.Provider>
    </TeaserOnPageContext.Provider>
  );
}

type State = 'default' | 'current' | 'live';

export default function useTeasersOnPage(state: State) {
  const context = React.useContext(TeaserOnPageContext);

  if (context === undefined) {
    throw new Error('useTeasersOnPage must be used within a TeasersOnPageProvider');
  }

  const memoizedTeaserHistory = React.useRef<Set<string>>(new Set<string>());

  if (state === 'live') {
    memoizedTeaserHistory.current = context;
    return context;
  }

  if (state === 'current' && !memoizedTeaserHistory.current.size) {
    memoizedTeaserHistory.current = context;
  }

  return memoizedTeaserHistory.current;
}

export function useSetTeasersOnPage() {
  const context = React.useContext(TeaserOnPageSetter);

  if (context === undefined) {
    throw new Error('useSetTeasersOnPage must be used within a TeasersOnPageProvider');
  }

  return context;
}

export function useTeasersOnPageAtom(state: State) {
  return [useTeasersOnPage(state), useSetTeasersOnPage()] as const;
}
