import usePreviewId from '@haaretz/s-atoms/previewId';

import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';
import type { AdSlotSizeMappingFragment } from '@haaretz/s-fragments/AdSlotSizeMapping';
import type { InlineStyles, Site } from '@haaretz/s-types';

export type FixedSize = [number, number];

const SITE_PRODUCTS = {
  htz: [243, 274],
  tm: [273, 274],
  hdc: [239],
};

const FLUID = -9999;

// Converts size [ -9999, 0 ] into 'fluid' literal
export const tranlateFluidSize = (size: number[]): googletag.SingleSize =>
  size[0] === FLUID ? 'fluid' : (size as googletag.SingleSizeArray);

// Get site prduct numbers by site code
export function getSiteProducts(site: Site) {
  return SITE_PRODUCTS[site];
}

// Tests if the Ad is a Fixed-size ad
export const isFixedSize = (
  sizes: AdSlotFragment['sizes']
): sizes is [googletag.SingleSizeArray] => {
  return (
    !!sizes &&
    sizes.length === 1 && // is there only one FixedSize
    sizes[0].length === 2 && // is fixed size contains width and height
    sizes[0][0] > 0 && // is width positie (and not 'fluid')
    sizes[0][1] > 0 // is height positive
  );
};

// converts Size into CSS style
export const size2CSS = (size: [FixedSize]): InlineStyles => {
  const s = size[0];
  return {
    minWidth: `${s[0]}px`,
    minHeight: `${s[1]}px`,
  };
};

// converts spinal-case to camel-case (margin-top => marginTop)
function spinalToCamelCase(key: string | null) {
  if (!key) {
    return key;
  }

  return key.replace(/-\w/g, v => v.charAt(1).toUpperCase());
}

// Converts css string to Obj
export const inlineStyle2Obj = (inlineStyle: string) => {
  const css: InlineStyles = {};

  inlineStyle
    .split(';')
    .map(kv => kv.split(':'))
    .forEach(kv => {
      if (kv?.length === 2) {
        // @ts-expect-error - this is actually okay
        css[spinalToCamelCase(kv[0].trim())] = kv[1].trim();
      }
    });

  return css;
};

// Creates a Media-Query string of (min-width: <width>px) and (max-width: <width>px)
export const toMediaQueryString = ([width1, width2]: [number, number?]) => {
  const [w1, w2] = [width1, width2]
    .filter((n): n is number => {
      return typeof n === 'number';
    })
    .sort((a: number, b: number) => a - b);

  return `(min-width:${w1}px${typeof w2 === 'number' ? `) and (max-width:${w2 - 1}px)` : ')'}`;
};

/**
 * Creates a MediaQueryList that triggers event when a viewport has empty sizes array
 * @param sizeMapping - The size-mapping object to create an observer for it
 * @returns MediaQueryList
 */
export function createEmptySizesMediaQuery(sizeMapping: AdSlotSizeMappingFragment[]) {
  let observer: MediaQueryList | null = null;

  if (!sizeMapping?.length) {
    return null;
  }

  const sortedMapping = sizeMapping.sort((sm1, sm2) => sm1.viewport[0] - sm2.viewport[0]);
  const mediaQueries = [];

  for (let i = 0; i < sortedMapping.length; i += 1) {
    if (sortedMapping[i].sizes.length === 0) {
      const width1 = sortedMapping[i].viewport[0];
      const width2 = sortedMapping[i + 1]?.viewport[0];

      mediaQueries.push(toMediaQueryString([width1, width2]));
    }
  }

  if (mediaQueries.length > 0) {
    const mqKey = `${mediaQueries.map(q => `(${q})`).join(' or ')}`;
    observer = window.matchMedia(mqKey);
  }

  return observer;
}

export function useRenderAdSlotPlaceholder() {
  const previewId = usePreviewId();
  const isPreview = !!previewId;
  const isStorybook = typeof window !== 'undefined' && !!window?.__IS_STORYBOOK__;

  return isPreview || isStorybook;
}
