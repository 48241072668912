'use client';

import { LineGraph } from '@haaretz/s-stock-market-graph';
import React from 'react';

import { StocksTabsContext } from './StocksTabsProvider';

export default function Graph() {
  const selectedGraphAssetId = React.useContext(StocksTabsContext);
  return <LineGraph assetId={selectedGraphAssetId ?? ''} homepage />;
}
