'use client';

import React from 'react';

import { registerViewPorts } from '../GAMMediaListener';
import { isFixedSize, size2CSS, tranlateFluidSize, createEmptySizesMediaQuery } from '../utils';

import AdCaption from './AdCaption';

import type { AdSlotEventHandlers } from '../AdSlot';
import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';
import type { InlineStyles } from '@haaretz/s-types';

export interface StaticAdSlotProps
  extends AdSlotEventHandlers,
    Omit<AdSlotFragment, 'inlineStyle' | 'interstitial' | 'divId'> {
  divId: googletag.enums.OutOfPageFormat | AdSlotFragment['divId'];
  targeting?: { [key: string]: string | string[] };
}

const ADS_REFRESH_TIMEOUT = 3 * 60000; // three minutes

let observer: IntersectionObserver;
if (typeof window !== 'undefined') {
  observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // refresh ad-slot
          const service = googletag.pubads();
          const slot = service.getSlots().find(s => s.getSlotElementId() === entry.target.id);

          slot && service.refresh([slot]);

          // remove observation
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    }
  );
}

export default function StaticAdSlot({
  contentId,
  adUnitPath,
  sizes,
  sizeMapping,
  targeting,
  divId,
  adCaption,
  slotOnLoad,
  slotImpressionViewable,
}: StaticAdSlotProps) {
  const [isShowCaption, setIsShowCaption] = React.useState(false);
  const slotRef = React.useRef<googletag.Slot>();
  const slotDivRef = React.useRef<HTMLDivElement>(null);

  // Calculate inine style object
  const style = React.useMemo(() => {
    let css: InlineStyles | undefined;

    if (isFixedSize(sizes)) {
      css = size2CSS(sizes);
    }

    return css;
  }, [sizes]);

  // Event handler for slot-onLoad event
  const slotOnLoadEventHandler = React.useMemo(() => {
    if (!slotOnLoad && !adCaption) {
      return null;
    }

    return (event: googletag.events.SlotOnloadEvent) => {
      if (slotRef.current === event.slot) {
        // Show ad-slot caption when ad-slot is loaded
        if (adCaption) {
          setIsShowCaption(true);
        }

        slotOnLoad && slotOnLoad(slotRef.current);
      }
    };
  }, [adCaption, slotOnLoad]);

  // Event handler for slot-impressionViewable event
  const impressionViewableEventHandler = React.useMemo(() => {
    if (!slotImpressionViewable) {
      return null;
    }

    return (event: googletag.events.ImpressionViewableEvent) => {
      if (slotRef.current === event.slot) {
        slotImpressionViewable(slotRef.current);
      }
    };
  }, [slotImpressionViewable]);

  React.useEffect(() => {
    if (!sizeMapping?.length || !adCaption) {
      return undefined;
    }

    const mediaQuery = createEmptySizesMediaQuery(sizeMapping);

    if (mediaQuery) {
      const changeHandler = (event: MediaQueryListEvent) => {
        if (event.matches) {
          setIsShowCaption(false);
        } else {
          setIsShowCaption(true);
        }
      };
      mediaQuery.addEventListener('change', changeHandler);

      return () => mediaQuery.removeEventListener('change', changeHandler);
    }

    return undefined;
  }, [sizeMapping, adCaption]);

  // initialize ad-slot
  React.useEffect(() => {
    if (!adUnitPath || !sizes) {
      return undefined;
    }

    let refreshTimeout: number;

    // Keep the ad-slot div reference into variable, for use when component unmounts
    const adDiv = slotDivRef.current;

    const adSizes = sizes.map(tranlateFluidSize);
    let adSizeMapping: googletag.SizeMappingArray | null;
    let slot: googletag.Slot | null | undefined = null;

    if (sizeMapping?.length) {
      // Create listeners for screen-width resize
      registerViewPorts(sizeMapping.map(sm => sm.viewport as googletag.SingleSizeArray));
    }

    // Google AD init
    googletag.cmd.push(() => {
      slot = googletag.defineSlot(adUnitPath, adSizes, `${divId}`)?.addService(googletag.pubads());
      slotRef.current = slot;

      if (!slot) {
        return;
      }

      if (sizeMapping && sizeMapping.length > 0) {
        const adSizeMappingBuilder = googletag.sizeMapping();

        sizeMapping.forEach(mapping => {
          adSizeMappingBuilder.addSize(
            mapping.viewport as googletag.SingleSizeArray,
            mapping.sizes.map(tranlateFluidSize)
          );
        });

        adSizeMapping = adSizeMappingBuilder.build();
        if (adSizeMapping) {
          slot.defineSizeMapping(adSizeMapping);
        }
      }

      if (targeting) {
        slot.updateTargetingFromMap(targeting);
      }

      if (slotOnLoadEventHandler) {
        googletag.pubads().addEventListener('slotOnload', slotOnLoadEventHandler);
      }

      if (impressionViewableEventHandler || observer) {
        googletag.pubads().addEventListener('impressionViewable', evt => {
          if (impressionViewableEventHandler) {
            impressionViewableEventHandler(evt);
          }

          if (slotRef.current === evt.slot && observer && slotDivRef.current !== null) {
            refreshTimeout = window.setTimeout(() => {
              if (slotDivRef.current !== null) {
                observer.observe(slotDivRef.current);
              }
            }, ADS_REFRESH_TIMEOUT);
          }
        });
      }

      googletag.display(slot);
    });

    // Destroy slot on un-mount
    return () => {
      if (slot) {
        if (slotOnLoadEventHandler) {
          googletag.pubads().removeEventListener('slotOnload', slotOnLoadEventHandler);
        }

        if (impressionViewableEventHandler) {
          googletag
            .pubads()
            .removeEventListener('impressionViewable', impressionViewableEventHandler);
        }

        if (observer && adDiv) {
          observer.unobserve(adDiv);
        }

        if (refreshTimeout) {
          window.clearTimeout(refreshTimeout);
        }

        googletag.destroySlots([slot]);
      }
    };
  }, [
    adUnitPath,
    sizes,
    sizeMapping,
    targeting,
    divId,
    adCaption,
    slotOnLoadEventHandler,
    impressionViewableEventHandler,
  ]);

  if (!adUnitPath) {
    return null;
  }

  return (
    <>
      {adCaption && isShowCaption ? (
        <AdCaption caption={adCaption} isVisible={isShowCaption} />
      ) : null}
      <div
        ref={slotDivRef}
        data-testid="static-ad-slot"
        id={`${divId}`}
        style={style}
        suppressHydrationWarning
        data-cid={contentId}
        data-adunit={adUnitPath}
      />
    </>
  );
}
