'use client';
import useTeasersOnPage from '@haaretz/s-atoms/teasersOnPage';

export default function useTeasers(status: 'live' | 'default') {
  const teasersOnPage = useTeasersOnPage(status);

  const result = teasersOnPage || /* istanbul ignore next */ [];

  return result;
}
