'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import Card from '@haaretz/s-card';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@haaretz/s-tabs';
import React from 'react';
import s9 from 'style9';

import Graph from './Graph';
import GraphTable from './GraphTable';
import MoreButton from './MoreButton';
import StocksTabsProvider, { StocksTabsContextUpdater } from './StocksTabsProvider';

const c = s9.create({
  card: {
    gridRowEnd: 'span 2',
    gridColumnStart: '1',
    gridColumnEnd: 'span 12',
    paddingInlineEnd: space(4),
    paddingInlineStart: space(4),
    paddingBottom: space(2),
    '--listLayoutGutter': space(3),
    gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
    display: 'grid',
    columnGap: 'var(--listLayoutGutter)',
    position: 'relative',

    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          gridColumnEnd: 'span 12',
        },
      }),
      mq({
        from: 'l',
        value: {
          gridColumnEnd: 'span 6',
        },
      }),

      // child grid
      mq({
        from: 's',
        until: 'xxl',
        value: {
          '--listLayoutGutter': space(2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          '--listLayoutGutter': space(5),
        },
      }),
      mq({
        from: 'l',
        value: {
          gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
        },
      })
    ),
  },
  tablist: {
    gridColumnStart: '1',
    gridColumnEnd: 'span 12',
    ...merge(
      mq({
        from: 'l',
        value: {
          gridColumnStart: '1',
          gridColumnEnd: 'span 6',
        },
      })
    ),
  },
  graph: {
    paddingTop: space(4),
    ...merge(
      mq({
        until: 's',
        value: {
          display: 'none',
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          gridColumnStart: '5',
          gridColumnEnd: '-1',
          gridRowStart: '2',
        },
      }),
      mq({
        from: 'l',
        value: {
          gridRowStart: '2',
          gridColumnStart: '3',
          gridColumnEnd: '-1',
        },
      })
    ),
  },
});

const tabs = [
  {
    name: 'תל אביב',
    assetsId: ['142', '137', '145', '164', '167'],
    tabFilters: [{ section: 'index', subSection: '2' }],
  },
  {
    name: 'וול סטריט',
    assetsId: ['30.10.!DJI', '33.10.!SPX', '29.10.@CCO', '29.10.NDX'],
    tabFilters: [{ section: 'index', subSection: '2' }],
  },
  {
    name: 'מט"ח',
    assetsId: ['90012', '9001', '90041', '9004', '90011'],
  },
  {
    name: 'קריפטו',
    assetsId: ['BTC', 'LTC', 'ETH', 'ZEC', 'XRP'],
  },
];

export default function StocksTableWithGraphsContainer() {
  return (
    <Card styleExtend={[c.card]}>
      <StocksTabsProvider>
        <StocksTableWithGraphs />
      </StocksTabsProvider>
    </Card>
  );
}

function StocksTableWithGraphs() {
  const [selectedTableRow, setSelectedTableRow] = React.useState(0);
  const setSelectedGraphAsset = React.useContext(StocksTabsContextUpdater);

  function handleTabClick(index: number) {
    setSelectedGraphAsset(tabs[index].assetsId[0]);
    setSelectedTableRow(index);
  }

  return (
    <Tabs>
      <TabList styleExtend={[c.tablist]} variant="bold">
        {tabs.map((tab, index) => (
          <Tab homepage key={`fry-tab-${tab.name}`} onClick={() => handleTabClick(index)}>
            {tab.name}
          </Tab>
        ))}
      </TabList>
      <GraphTable assetIds={tabs[selectedTableRow].assetsId} />
      <TabPanels>
        {tabs.map(tab => (
          <TabPanel key={`fry-panel-${tab.name}`} styleExtend={[c.graph]}>
            <Graph />
          </TabPanel>
        ))}
      </TabPanels>
      <MoreButton assetIds={tabs[selectedTableRow].assetsId} />
    </Tabs>
  );
}
