'use server';
import { isDebugMode } from '@haaretz/s-debug-info/server';

import listMap from '../../utils/listMap';

import type { MichelangeloListFragment } from '@haaretz/s-fragments/lists/MichelangeloFragment.list';
import type { BaseServerActionParams, ListStyle } from '@haaretz/s-types';

interface AbTestListLoaderProps extends BaseServerActionParams {
  listStyle: ListStyle;
  variables: {
    id: string;
    mainContentId?: string;
    exclude: string;
    page?: number;
  };
}

const defaultResponse = {
  html: null,
};

export default async function loadAbTestList({
  listStyle,
  variables,
}: AbTestListLoaderProps): Promise<{
  html: React.ReactNode;
  result?: string;
}> {
  try {
    const List = listViewExists(listStyle) ? listMap[listStyle] : null;

    if (!List?.fetchQuery) {
      return defaultResponse;
    }

    const data = await List.fetchQuery(variables)();

    if (
      !data.List ||
      !(
        data.List?.items?.length ||
        (data.List as MichelangeloListFragment)?.banners?.clickTrackerBanners?.length ||
        (data.List as MichelangeloListFragment)?.banners?.adSlots?.length
      )
    ) {
      return defaultResponse;
    }

    const ownProps = await List.getOwnProps();

    return {
      html: <List.View {...data.List} {...ownProps} kind="lazy" />,
      ...(isDebugMode() ? /* istanbul ignore next */ { result: JSON.stringify(data) } : {}),
    };
  } catch (error) {
    console.error(error);
    return defaultResponse;
  }
}

function listViewExists(listStyle: string): listStyle is keyof typeof listMap {
  return listStyle in listMap;
}
