'use client';

import { useSetTeasersOnPage } from '@haaretz/s-atoms/teasersOnPage';
import React from 'react';

interface TeasersOnPageMutatorProps {
  teaserId: string;
}
export default function TeasersOnPageMutator({ teaserId }: TeasersOnPageMutatorProps) {
  const setTeasersOnPage = useSetTeasersOnPage();

  React.useEffect(() => {
    // NOTE: this is a side effect that will be called once per teaser
    // The hook `@haaretz/s-use-once` not working here because the hook is called only once
    // and teaserId deleting from the array after the first render
    // so the hook will not be called again when app run in development mode
    // and reactStrictMode is on

    setTeasersOnPage(teaserId, 'add');

    return () => {
      setTeasersOnPage(teaserId, 'delete');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
