import type { ListPageType, PageType } from '@haaretz/s-types';

export default function getArticleType(pageType: PageType): ListPageType {
  let articleType: ListPageType = 'section';

  if (pageType.endsWith('Article')) {
    articleType = 'article';
  } else if (pageType === 'Homepage') {
    articleType = 'homepage';
  }

  return articleType;
}
