import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import React from 'react';
import s9 from 'style9';

export type TableType = 'index' | 'graphs';

const c = s9.create({
  th: {
    fontWeight: '400',
    textAlign: 'start',
    color: color('neutral1000'),
    ...typesetter(-1),
    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  thPaddingStart: {
    ...merge(mq({ from: 's', value: { paddingInlineStart: space(2) } })),
  },
  thPaddingEnd: {
    ...merge(mq({ from: 's', value: { paddingInlineEnd: space(2) } })),
  },
  thContent: {
    paddingTop: space(3),
    paddingBottom: space(1),
    backgroundColor: color('neutral200'),
  },
  thName: {
    paddingInlineStart: space(3),
  },
  thValue: {
    whiteSpace: 'nowrap',
    ...merge(mq({ until: 's', value: { width: '90px' } })),
  },
  hiddenFromS: {
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  hiddenUntilS: {
    ...merge(mq({ until: 's', value: { display: 'none' } })),
  },
  td: {
    position: 'relative',
    paddingTop: space(3),
    paddingBottom: space(3),
    height: space(11),
    ...typesetter(-1),
    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  nameTd: {
    paddingInlineStart: space(3),
    paddingInlineEnd: space(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    maxWidth: '0',
  },
  valueTd: {
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  changePercentageTd: {
    paddingInlineEnd: space(4),
  },
  positivePercentageChange: {
    color: color('quaternary700'),
  },
  negativePercentageChange: {
    color: color('secondary900'),
  },
  tr: {
    backgroundColor: color('contentBg'),
    ...border({
      spacing: 1,
      side: 'bottom',
      color: color('neutral200'),
      width: '4px',
      style: 'solid',
    }),
    ':hover': {
      backgroundColor: color('neutral200'),
    },
  },
  trWithoutBorder: {
    ...border({
      side: 'bottom',
      style: 'none',
    }),
  },
  trActive: {
    backgroundColor: color('neutral200'),
  },
});

export type ColumnType = 'name' | 'value' | 'changePercentage';

export function Thead({ tableType }: { tableType?: TableType }) {
  return (
    <thead>
      <tr>
        <th className={s9(c.th, tableType === 'index' && c.thPaddingStart)}>
          <div className={s9(c.thContent, c.thName)}>שם נייר</div>
        </th>
        <th className={s9(c.th, c.valueTd, c.hiddenFromS)}>
          <div className={s9(c.thContent, c.thValue)}>שער אחרון</div>
        </th>
        <th className={s9(c.th, tableType === 'index' && c.thPaddingEnd)}>
          <div className={s9(c.thContent)}>% שינוי</div>
        </th>
      </tr>
    </thead>
  );
}

export function Tr({
  isLast,
  isActive,
  children,
}: {
  isLast: boolean;
  isActive?: boolean;
  children: React.ReactNode;
}) {
  return (
    <tr className={s9(c.tr, isLast && c.trWithoutBorder, isActive && c.trActive)}>{children}</tr>
  );
}

export function Td({ column, children }: { column: ColumnType; children: React.ReactNode }) {
  const tdStyles: `${typeof column}Td` = `${column}Td`;
  return <td className={s9(c.td, c[tdStyles])}>{children}</td>;
}

export function ChangePercentageTd({ change }: { change: number }) {
  const isChangePositive = change >= 0;
  return (
    <span
      dir="ltr"
      className={s9(isChangePositive ? c.positivePercentageChange : c.negativePercentageChange)}
    >
      {isChangePositive ? '+' : ''}
      {change}%
    </span>
  );
}
