import { hostname } from '@haaretz/s-consts';

import fetcher from '../../fetcher';

import type { DefaultSearchResponseType, SearchPayloadType } from '../../fetcher/fetcher';

export interface ResponseType extends DefaultSearchResponseType {
  r: {
    pd: {
      author: string;
      creationTime: number;
      description?: string;
      img: string;
      modifyTime: number;
      publicUrl: string;
      publishTime: number;
      title: string;
      url: string;
    };
    wd?: string[];
    hqd?: string[][];
  }[];
}

interface SearchResultsFetcherOwnProps {
  kind: 'recipes' | 'www';
}

export default async function searchResultsFetcher({
  kind,
  ...args
}: Required<
  Pick<SearchPayloadType, 'p' | 'q'> & SearchResultsFetcherOwnProps
>): Promise<ResponseType> {
  return fetcher<ResponseType>('/search/s/', {
    ...args,
    h: `${kind}.${hostname}`,
  });
}
