'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import HtzImage from '@haaretz/s-htz-image';
import Icon from '@haaretz/s-icon';
import parseFullImageSrc from '@haaretz/s-image-utils/parseFullImageSrc';
import searchFetcher from '@haaretz/s-search-utils/search-food-fetcher';
import searchResultsFetcher from '@haaretz/s-search-utils/search-results-fetcher';
import TextField from '@haaretz/s-text-field';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import { useCombobox } from 'downshift';
import { debounce } from 'mini-debounce';
import { useRouter } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import type { ResponseType } from '@haaretz/s-search-utils/search-fetcher';
import type { UseComboboxStateChange } from 'downshift';

const keyDownEnterType = useCombobox.stateChangeTypes.InputKeyDownEnter;
const keyDownEscapeType = useCombobox.stateChangeTypes.InputKeyDownEscape;

type RecipeResultType = ResponseType['n'][number]['pd'];

const borderWidth = '1px';

const c = s9.create({
  listPlacement: {
    gridColumnStart: 1,
    gridColumnEnd: -1,
    ...merge(
      mq({
        from: 'l',
        value: {
          gridColumnStart: 3,
        },
      })
    ),
  },

  textField: {
    '--inp-bgc': color('neutral100'),
  },
  fullWidth: {
    width: '100%',
  },
  recipeResult: {
    display: 'grid',
    gridTemplateColumns: 'auto minmax(0, 1fr)',
  },
  ul: {
    backgroundColor: color('neutral100'),
    borderEndStartRadius: radius('small'),
    borderEndEndRadius: radius('small'),
    position: 'absolute',
    width: '100%',
    zIndex: zIndex('dropdown'),
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'bottom',
    }),
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 0,
      side: 'inline-start',
    }),
    ...border({
      width: borderWidth,
      color: 'var(--inp-brdr-c)',
      style: 'solid',
      spacing: 0,
      side: 'inline-end',
    }),
  },
  hr: {
    marginBottom: space(5),
    marginInlineStart: space(2),
    marginInlineEnd: space(2),
  },
  item: {
    paddingTop: space(4),
    paddingBottom: space(4),
    cursor: 'pointer',
  },
  selectedItem: {
    backgroundColor: color('primary100'),
  },

  hidden: {
    display: 'none',
  },
  relative: {
    position: 'relative',
  },
  articleImage: {
    marginInlineEnd: space(3),
    width: space(18),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          width: space(14),
        },
      })
    ),
  },
  author: {
    gridTemplateColumns: 'auto 1fr',
    ...merge(
      mq({
        from: 'xxl',
        value: {
          ...typesetter(0),
        },
      })
    ),
  },
  sizeIndent: {
    paddingInlineStart: space(2),
    paddingInlineEnd: space(2),
  },
  title: {
    marginBottom: space(4),
    fontWeight: 700,
    ...merge(
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  magnifyerIcon: {
    ...merge(mq({ until: 's', value: { fontSize: space(5) } })),
  },
  iconBtn: {
    backgroundColor: 'transparent',
  },
});

type RecipeResultProps = {
  recipe: RecipeResultType;
};

function RecipeResult({ recipe }: RecipeResultProps) {
  const imageData = parseFullImageSrc(recipe.img || '');
  return (
    <div className={s9(c.recipeResult)}>
      {recipe.img && imageData ? (
        <HtzImage
          styleExtend={[c.articleImage]}
          __typename="htz_image_Image"
          alt={recipe.title}
          contentId={imageData.contentId}
          imgData={{
            __typename: 'ImageViewModel_ImageFile',
            ...imageData,
            crops: {
              __typename: 'ImageCrops',
              ...(imageData.precropParams.width && {
                square: imageData.precropParams,
              }),
            },
          }}
          aspect="regular"
          type="image"
          sizes={[{ size: '58px' }]}
          envOverride="prod"
          widths={[58]}
        />
      ) : null}
      <strong>{recipe.title}</strong>
    </div>
  );
}

type ResultProps = {
  item: string;
  // inputValue: string;
};

function Result({ item }: ResultProps) {
  return (
    <>
      {/* <strong>{inputValue}</strong> */}
      <span>{item}</span>
    </>
  );
}

function ResultDivider() {
  return (
    <>
      <hr className={s9(c.hr)} />
      <div className={s9(c.title, c.sizeIndent)}>{fork({ default: 'מתכונים מולצים' })}</div>
    </>
  );
}

type SearchItemType = RecipeResultType | string;

export interface SearchFormProps {
  initialInputValue?: string;
  placeOn: 'list' | 'searchPage';
}

export default function SearchFoodForm({ initialInputValue, placeOn }: SearchFormProps) {
  const inputBlurReason = React.useRef<'TabOut' | 'Enter' | null>(null);
  const selectedItemFallback = React.useRef<SearchItemType | null>(null);
  const router = useRouter();
  const biAction = useBi();
  const textFieldRef = React.useRef<HTMLInputElement>(null);
  const isListPlacement = placeOn === 'list';

  function onSelectedItemChange({
    selectedItem,
    type,
    isOpen,
  }: UseComboboxStateChange<SearchItemType>) {
    if (!selectedItem && !isOpen && type === keyDownEscapeType) {
      router.back();
      return;
    }

    if (inputBlurReason.current === 'TabOut') {
      inputBlurReason.current = null;

      return;
    }

    if (typeof selectedItem === 'object' && selectedItem) {
      // Food search
      biAction({
        action_id: 171,
        feature: 'Search Field',
        campaign_name: 'Food',
        campaign_details: 'Recipe',
        feature_type: 'Content',
      });

      window.location.assign(selectedItem.publicUrl);
      return;
    }

    // Regular search
    biAction({
      action_id: 144,
      feature: 'Search Field',
      campaign_name: 'Food',
      campaign_details: 'Auto complete',
      feature_type: 'Content',
    });

    router[isListPlacement ? 'push' : 'replace'](`/ty-search-food?q=${selectedItem}`);
  }

  const [inputItems, setInputItems] = React.useState<SearchItemType[]>([]);

  const search = React.useMemo(
    () =>
      debounce(async (q: string) => {
        const resultAutoComplete = await searchFetcher({
          q,
          attribute: 'recipe',
        });
        try {
          const data = await searchResultsFetcher({
            q: resultAutoComplete.r[0],
            p: 1,
            kind: 'recipes',
          });
          setInputItems([
            ...resultAutoComplete.r.slice(0, 3),
            ...data.r.slice(0, 3).map(({ pd }) => pd),
          ]);
        } catch (error) {
          console.error(`Search server action failed: ${(error as Error).message}`);
          setInputItems([...resultAutoComplete.r.slice(0, 6)]);
        }
      }, 300),
    []
  );

  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    inputValue,
    isOpen,
    closeMenu,
    selectItem,
    ...restProps
  } = useCombobox({
    items: inputItems,
    itemToString(item) {
      if (typeof item === 'object' && item) {
        return item.title;
      }

      return item || '';
    },
    onInputValueChange: async ({ inputValue: q }) => {
      if (!q) {
        setInputItems([]);
        return;
      }

      search(q);
    },
    onSelectedItemChange,
    initialInputValue,
  });

  const isShowingResults = isOpen && !!inputItems.length;

  return (
    <form
      className={s9(c.fullWidth, c.relative, isListPlacement && c.listPlacement)}
      onSubmit={event => {
        event.preventDefault();

        if (restProps.selectedItem) {
          onSelectedItemChange({
            selectedItem: restProps.selectedItem,
            type: keyDownEnterType,
          });
        } else if (inputValue) {
          onSelectedItemChange({
            selectedItem: inputValue.trim(),
            type: keyDownEnterType,
          });
        } else if (textFieldRef.current?.value) {
          onSelectedItemChange({
            selectedItem: textFieldRef.current?.value.trim(),
            type: keyDownEnterType,
          });
        }
      }}
    >
      <VisuallyHidden {...getLabelProps()} as="label">
        {fork({ default: 'חיפוש מתכונים', hdc: 'Search' })}
      </VisuallyHidden>
      <TextField
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={!isListPlacement}
        placeholder="הקלידו לחיפוש מתכונים באתר"
        opaque
        required={false}
        styleExtend={[c.textField, c.fullWidth]}
        startIcon={
          isListPlacement && (
            <button type="submit" className={s9(c.iconBtn)}>
              <Icon icon="search" variant="brand" styleExtend={[c.magnifyerIcon]} />
            </button>
          )
        }
        endIcon={
          !isListPlacement && (
            <button type="submit" className={s9(c.iconBtn)}>
              <Icon icon="search" styleExtend={[c.magnifyerIcon]} />
            </button>
          )
        }
        sharp={isShowingResults ? 'bottom' : undefined}
        hideBorder={isShowingResults ? 'bottom' : undefined}
        size="large"
        {...getInputProps({
          onKeyDown: event => {
            switch (event.key) {
              case 'Enter':
              case 'Tab': {
                let newSelectedItem: SearchItemType | undefined;
                if (isOpen) {
                  event.preventDefault();

                  if (event.key === 'Tab') {
                    inputBlurReason.current = 'TabOut';
                  }

                  newSelectedItem = inputItems[highlightedIndex];

                  if (newSelectedItem) {
                    selectedItemFallback.current = newSelectedItem;

                    selectItem(newSelectedItem);
                  }

                  closeMenu();
                }

                if (event.key === 'Enter') {
                  inputBlurReason.current = 'Enter';

                  onSelectedItemChange({
                    selectedItem: newSelectedItem || (event.target as HTMLInputElement).value,
                    type: keyDownEnterType,
                    isOpen: false,
                  });
                }
                break;
              }
              default: {
                break;
              }
            }

            selectedItemFallback.current = null;
          },
          ref: textFieldRef,
        })}
      />

      <ul {...getMenuProps()} className={s9(c.ul, !isShowingResults && c.hidden)}>
        {isShowingResults
          ? inputItems.map((item, index) => {
              const isRecipe = typeof item === 'object';
              const previousItemIsString = typeof inputItems[index - 1] === 'string';
              return (
                <React.Fragment key={typeof item === 'object' ? item.publicUrl : item}>
                  {isRecipe && previousItemIsString ? <ResultDivider /> : null}
                  <li
                    className={s9(
                      c.item,
                      highlightedIndex === index && c.selectedItem,
                      c.sizeIndent
                    )}
                    key={`${item}`}
                    {...getItemProps({ item, index })}
                  >
                    {isRecipe ? <RecipeResult recipe={item} /> : <Result item={item} />}
                  </li>
                </React.Fragment>
              );
            })
          : null}
      </ul>
    </form>
  );
}
