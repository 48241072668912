'use client';
import usePage from '@haaretz/s-atoms/page';
import useTags from '@haaretz/s-atoms/tags';
import useList from '@haaretz/s-use-list';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import React from 'react';

import useTeasers from '../../hooks/useTeasers';
import abTestListLoader from '../../server-actions/abTestListLoader';
import getArticleType from '../../utils/getArticleType';

import type { AbTestListCommonProps, WithClientListProps } from '@haaretz/s-data-structure-types';
import type { ListFragment } from '@haaretz/s-fragments/List';
import type { PageType } from '@haaretz/s-types';

export type AbTestListWrapperProps = WithClientListProps<ListFragment> & {
  pageType: PageType;
} & AbTestListCommonProps;

interface AbListProps extends Omit<AbTestListWrapperProps, 'ListLayout'> {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

export default function AbTestList({
  children: placeholder,
  fallback,
  ...abTestProps
}: Omit<AbListProps, 'ListLayout' | 'ListPlaceholder'>) {
  const { abTestContentId } = abTestProps;
  const { inView, obeservedElement } = useList();
  const page = usePage();
  const [, startTransition] = React.useTransition();
  const isAbTestList = abTestProps.kind === 'abTest';
  const isLoaded = useLoadEvent();
  const [list, setList] = React.useState<React.ReactNode>(undefined);

  const pageType = getArticleType(abTestProps.pageType);

  const prevContentIdRef = React.useRef<string | undefined>();

  const { pageContentId, articleSectionId } = page || {};

  const shouldLoading =
    isAbTestList && isLoaded && inView && prevContentIdRef.current !== pageContentId;

  const teasersOnPage = useTeasers(shouldLoading ? 'live' : 'default');

  const tags = useTags();
  const tagIds = tags?.map(tag => tag.contentId);

  const handleLoadAbTestList = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const result = await abTestListLoader({
            postCacheParams: 'sa-list-ab-test-personal',
            listStyle: abTestProps.listStyle,
            variables: {
              testId: abTestContentId,
              pageType,
              exclude: Array.from(teasersOnPage).toString(),
              ...(pageContentId && articleSectionId
                ? {
                    articleId: pageContentId,
                    sectionId: articleSectionId,
                    tagsIds: tagIds?.join(',') ?? '',
                  }
                : {
                    articleId: undefined,
                  }),
            },
          });

          /* istanbul ignore next */
          if (result?.url) {
            console.table({
              title: 'AbTestList',
              style: abTestProps.listStyle,
              contentId: abTestProps.contentId,
              url: result.url,
              body: result.body,
              result: result.result,
            });
          }

          setList(result?.html || fallback);
        } catch (error) /* istanbul ignore next */ {
          console.error(error);
          setList(fallback);
        }
      });
    },
    [
      abTestContentId,
      abTestProps.contentId,
      abTestProps.listStyle,
      articleSectionId,
      fallback,
      pageContentId,
      pageType,
      tagIds,
      teasersOnPage,
    ]
  );

  React.useEffect(() => {
    if (shouldLoading) {
      prevContentIdRef.current = pageContentId;
      handleLoadAbTestList();
    }
  }, [handleLoadAbTestList, shouldLoading, pageContentId]);

  if (list) {
    return list;
  }

  return (
    <>
      {obeservedElement}
      {placeholder}
    </>
  );
}
