'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Icon from '@haaretz/s-icon';
import Popover from '@haaretz/s-popover';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

function defaultPopoverContent(advertiser: string) {
  return fork({
    default: `תוכן ממומן בשיתוף ${advertiser}.
      הכתבה הופקה ונערכה על ידי המחלקה המסחרית של הארץ`,
    hdc: `Paid by ${advertiser}
    The article was produced by the commercial department of Haaretz`,
  });
}

// `c` is short for `classNames`
const c = s9.create({
  footer: {
    zIndex: zIndex('above'),
    position: 'relative',
  },
  adsDescription: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: color('promoted300'),
    outline: 'none',
  },
  icon: {
    minWidth: space(4),
    minHeight: space(4),
    marginInlineEnd: space(1),
    alignSelf: 'flex-start',
    marginBlockStart: space(0.5),
  },
  popoverContent: {
    width: space(30),
    ...merge({
      ...mq({ from: 'l', value: { width: space(45) } }),
    }),
  },
  popoverTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  adsText: {
    textAlign: 'start',
    ...typesetter(-2),

    ...merge({
      ...mq({ from: 'l', value: { ...typesetter(-1) } }),
      ...mq({ from: 'xl', value: { ...typesetter(-2) } }),
      ...mq({ from: 'xxl', value: { ...typesetter(-3) } }),
    }),
  },
});

export interface ClickTrackerFooterProps {
  inlineStyle?: InlineStyles;
  styleExtend?: StyleExtend;
  adsTextStyleExtend?: StyleExtend;
  advertiser?: string;
  popoverText?: string;
}

export default function ClickTrackerFooter({
  inlineStyle,
  styleExtend = [],
  advertiser,
  popoverText,
  adsTextStyleExtend = [],
}: ClickTrackerFooterProps) {
  const adsRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenStateChangeHandler = (isOpenFromChild: boolean) => {
    setIsOpen(isOpenFromChild);
  };

  if (!advertiser) {
    return null;
  }

  return (
    <footer className={s9(c.footer, ...styleExtend)} style={inlineStyle}>
      <div className={s9(c.adsDescription)}>
        <span
          className={s9(c.adsText, c.popoverTitle, ...adsTextStyleExtend)}
          onMouseMove={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <Icon icon="warn" styleExtend={[c.icon]} ref={adsRef} />
          <span>
            {fork({ default: 'בשיתוף ', hdc: 'Paid by' })}
            {advertiser}
          </span>
        </span>
      </div>
      <Popover
        kind="tooltip"
        refersToRef={adsRef}
        variant="inverse"
        placement="top-start"
        onToggle={handleOpenStateChangeHandler}
        offsetValue={{ crossAxis: space(-2) }}
        isOpen={isOpen}
      >
        <div className={s9(c.popoverContent, c.adsText)}>
          {popoverText || defaultPopoverContent(advertiser)}
        </div>
      </Popover>
    </footer>
  );
}
