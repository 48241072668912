'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Button from '@haaretz/s-button';
import Card from '@haaretz/s-card';
import Icon from '@haaretz/s-icon';
import React from 'react';
import s9 from 'style9';

import { isGallery } from '../SlimList.view';

import GalleryImage from './GalleryImage';

import type { SlimListFragment } from '@haaretz/s-fragments/lists/SlimFragment.list';
import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

// NOTE: we divide the height by two here,
// to center the buttons according to image,
// excluding the caption text

const sCaptionHeight = space(13 / 2);
const xlCaptionHeight = space(15 / 2);
const xxlCaptionHeight = space(17 / 2);

const animationDuration = 500;

const c = s9.create({
  listTitle: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'block',
          gridColumnEnd: 'span 12',
          paddingTop: space(2),
          color: color('textLightAllModes'),
          paddingBottom: space(1),
        },
      })
    ),
  },
  base: {
    display: 'none',

    ...merge(
      mq({
        from: 's',
        value: {
          display: 'block',
          borderRadius: radius('sharp'),
          position: 'relative',
          width: 'fit-content',
        },
      }),
      mq({
        until: 'xl',
        value: {
          marginInlineStart: 'calc(-1 * var(--LayoutContainerGutter))',
          marginInlineEnd: 'calc(-1 * var(--LayoutContainerGutter))',
          gridColumnEnd: 'span 12',
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          gridColumnEnd: 'span 9',
          gridRowEnd: 'span 3',
        },
      }),
      mq({
        from: 'xxl',
        value: {
          gridRowEnd: 'span 3',
          gridColumnEnd: 'span 8',
        },
      })
    ),
  },
  buttonWrapper: {
    '--caption-height': sCaptionHeight,
    zIndex: zIndex('above'),
    position: 'absolute',
    top: '50%',
    transform: 'translateY(calc(-50% - var(--caption-height)))',
    transition: 'none',
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          '--caption-height': xlCaptionHeight,
        },
      }),
      mq({
        from: 'xxl',
        value: {
          '--caption-height': xxlCaptionHeight,
        },
      })
    ),
  },
  button: {
    borderRadius: radius('sharp'),

    ...merge(
      mq({
        from: 's',
        value: {
          height: space(18),
          width: space(7),
          paddingInlineStart: 0,
          paddingInlineEnd: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      })
    ),
  },
  buttonNext: {
    insetInlineStart: '0',
  },
  buttonPrevious: {
    insetInlineEnd: '0',
  },
  iconNext: {
    display: 'flex',
    justifyContent: 'center',
    rotate: fork({ default: '180deg', hdc: '0' }),
  },
  iconPrevious: {
    rotate: fork({ default: '0', hdc: '180deg' }),
  },
  item: {
    height: '100%',
    position: 'absolute',
    top: '0',
    width: '100%',
  },
  currentItem: {
    insetInlineStart: '0',
    position: 'relative',
    zIndex: 0,
  },
  nextItem: {
    insetInlineStart: '100%',
  },
  previousItem: {
    insetInlineStart: '-100%',
  },
  animate: {
    visibility: 'visible',
    transitionProperty: 'insetInlineStart, transform',
    transitionTimingFunction: 'cubic-bezier(.55, 0, .1, 1)',
    transitionDuration: `${animationDuration}ms`,
  },
  transitionToCurrent: {
    insetInlineStart: '0',
  },
  transitionToPrevious: {
    insetInlineStart: '100%',
  },
  transitionToNext: {
    insetInlineStart: '-100%',
  },
});

type Direction = 'toPrevious' | 'toNext' | undefined;
type SlimListItem = SlimListFragment['items'][number];

interface GalleryProps extends SlimListItem {
  styleExtend?: StyleExtend;
  inlineStyles?: InlineStyles;
}

const threshold = 5;

export default function Carousel(props: GalleryProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [nextIndex, setNextIndex] = React.useState(0);
  const [previousIndex, setPreviousIndex] = React.useState(0);

  const gallery = isGallery(props?.media) ? props.media : null;
  const length = gallery?.images?.length ?? 1;

  const getUpdatedIndex = React.useCallback(
    (direction: Direction) => {
      if (direction === 'toNext') return currentIndex === length - 1 ? 0 : currentIndex + 1;
      if (direction === 'toPrevious') return currentIndex <= 0 ? length - 1 : currentIndex - 1;
      else return 0;
    },
    [currentIndex, length]
  );

  const [animationDirection, setAnimationDirection] = React.useState<Direction>(undefined);
  const isAnimating = animationDirection !== undefined;

  const [movedX, setMovedX] = React.useState(0);

  const galleryRef = React.useRef<HTMLDivElement>(null);
  const initialTouchPositionRef = React.useRef<number | null>(null);

  if (!gallery?.images || !length) return null;

  function handleTransitionEnd() {
    if (animationDirection === 'toNext') setCurrentIndex(nextIndex);
    if (animationDirection === 'toPrevious') setCurrentIndex(previousIndex);

    setAnimationDirection(undefined);
  }

  function handleGoToNext() {
    setAnimationDirection('toNext');
    setNextIndex(getUpdatedIndex('toNext'));
  }

  function handleGoToPrevius() {
    setAnimationDirection('toPrevious');
    setPreviousIndex(getUpdatedIndex('toPrevious'));
  }

  function handleTouchStart(event: React.TouchEvent) {
    setNextIndex(getUpdatedIndex('toNext'));
    setPreviousIndex(getUpdatedIndex('toPrevious'));

    const touch = event.touches[0].clientX;
    initialTouchPositionRef.current = touch;
  }

  function handleTouchMove(event: React.TouchEvent) {
    if (!galleryRef.current) return;

    const touch = event.touches[0].clientX;
    const initialTouchX = initialTouchPositionRef.current;
    const touchMovement = touch - (initialTouchX ?? 1);

    const { width } = galleryRef.current.getBoundingClientRect();

    const percentage = !width ? 0 : (touchMovement / width) * 100;

    setMovedX(percentage);
  }

  function handleTouchEnd() {
    initialTouchPositionRef.current = null;

    if (Math.abs(movedX) < threshold) {
      setMovedX(0);
      return;
    }
    if (fork({ default: movedX >= threshold, hdc: movedX <= threshold })) {
      handleGoToNext();
    } else if (fork({ default: movedX <= threshold, hdc: movedX >= threshold })) {
      handleGoToPrevius();
    }

    setMovedX(0);
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.code === fork({ default: 'ArrowLeft', hdc: 'ArrowRight' })) {
      handleGoToNext();
    }
    if (e.code === fork({ default: 'ArrowRight', hdc: 'ArrowLeft' })) {
      handleGoToPrevius();
    }
  }

  return (
    <Card
      variant="emphasis"
      styleExtend={[c.base]}
      data-testid="imageGallery"
      ref={galleryRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onKeyDownCapture={handleKeyDown}
    >
      <div className={s9(c.buttonWrapper, c.buttonPrevious)}>
        <Button variant="brand" onClick={handleGoToNext} styleExtend={[c.button]}>
          <Icon icon="chevron" styleExtend={[c.iconNext]} />
        </Button>
      </div>
      <div
        className={s9(
          c.item,
          c.nextItem,
          animationDirection === 'toNext' && c.transitionToCurrent,
          isAnimating && c.animate
        )}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateX(${movedX}%)` }}
      >
        <GalleryImage length={length} index={nextIndex} image={gallery.images[nextIndex]} />
      </div>
      <div
        className={s9(
          c.item,
          c.currentItem,
          isAnimating && c.animate,
          animationDirection === 'toNext' && c.transitionToNext,
          animationDirection === 'toPrevious' && c.transitionToPrevious
        )}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateX(${movedX}%)` }}
      >
        <GalleryImage length={length} index={currentIndex} image={gallery.images[currentIndex]} />
      </div>

      <div
        className={s9(
          c.item,
          c.previousItem,
          isAnimating && c.animate,
          animationDirection === 'toPrevious' && c.transitionToCurrent
        )}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateX(${movedX}%)` }}
      >
        <GalleryImage length={length} index={previousIndex} image={gallery.images[previousIndex]} />
      </div>
      <div className={s9(c.buttonWrapper, c.buttonNext)}>
        <Button variant="brand" styleExtend={[c.button]} onClick={handleGoToPrevius}>
          <Icon icon="chevron" styleExtend={[c.iconPrevious]} />
        </Button>
      </div>
    </Card>
  );
}
