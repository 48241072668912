'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { ffLabel, playLabel, rwLabel, skipTime } from '../utils/audioPlayerUtils';

const c = s9.create({
  skipButton: {
    fontSize: space(6),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          fontSize: space(7),
        },
      })
    ),
  },
  skipLabel: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    top: '30%',
    fontWeight: 700,
    ...typesetter(-4),
    ...merge(
      mq({
        from: 'xl',
        value: {
          ...typesetter(-6),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          top: '32%',
        },
      })
    ),
  },
  rw: {
    // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
    gridColumnStart: fork({ default: '3', hdc: '2' }),
    gridColumnEnd: fork({ default: '4', hdc: '3' }),
    gridRowStart: '3',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    ...merge(
      mq({
        from: 's',
        value: {
          alignItems: 'center',
          // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
          gridColumnStart: fork({ default: '-1', hdc: '-3' }),
          gridColumnEnd: fork({ default: '-2', hdc: '-4' }),
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  ff: {
    // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
    gridColumnStart: fork({ default: '2', hdc: '3' }),
    gridColumnEnd: fork({ default: '3', hdc: '4' }),
    gridRowStart: '3',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    ...merge(
      mq({
        from: 's',
        value: {
          alignItems: 'center',
          // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
          gridColumnStart: fork({ default: '-3', hdc: '-1' }),
          gridColumnEnd: fork({ default: '-4', hdc: '-2' }),
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  audioWrap: {
    gridColumnStart: '4',
    gridColumnEnd: '5',
    gridRowStart: '2',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...merge(
      mq({
        from: 's',
        value: {
          gridColumnStart: '-2',
          gridColumnEnd: '-3',
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          margin: `0 ${space(-1)}`,
        },
      })
    ),
  },
  audioButtonWrapperSchoonhoven: {
    ...merge(
      mq({
        from: 'l',
        until: 'xxl',
        value: { margin: `0 ${space(-3)}` },
      }),
      mq({
        from: 'xxl',
        value: { margin: `0 ${space(-2)}` },
      })
    ),
  },
  audioButton: {
    fontSize: space(16),
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: radius('circle'),
    backgroundColor: color('primary1000'),
    ...merge(
      mq({
        from: 's',
        value: {
          fontSize: space(12),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          fontSize: space(14),
        },
      })
    ),
  },
  audioButtonSmall: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          fontSize: space(11),
        },
      }),
      mq({
        from: 'l',
        until: 'xxl',
        value: {
          fontSize: space(13),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          fontSize: space(14),
        },
      })
    ),
  },
  icon: {
    fontSize: space(8),
    width: '1em',
    height: '1em',
    ...merge(
      mq({
        from: 's',
        value: {
          fontSize: space(6),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          fontSize: space(7),
        },
      })
    ),
  },
  skipButtonSchoonhoven: {
    ...merge(
      mq({
        from: 'l',
        until: 'xxl',
        value: {
          fontSize: space(7),
        },
      })
    ),
  },
  skipLabelSchoonhoven: {
    ...merge(
      mq({
        from: 'l',
        value: {
          top: '32%',
        },
      })
    ),
  },
  iconPlay: {
    transform: 'translateX(10%)',
  },
  button: {
    backgroundColor: 'inherit',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
interface AudioControlsProps {
  variant?: 'default' | 'schoonhoven';
}

export default function AudioControls({ variant = 'default' }: AudioControlsProps) {
  const { isPlaying, onSkip, onPauseButtonClick, onPlayButtonClick } = useAudioPlayer();
  const isListVariant = variant === 'schoonhoven';
  return (
    <>
      <div className={s9(c.ff)}>
        <button onClick={() => onSkip(skipTime)} className={s9(c.button)}>
          <VisuallyHidden>{ffLabel}</VisuallyHidden>
          <Icon
            styleExtend={[c.skipButton, isListVariant && c.skipButtonSchoonhoven]}
            icon="audio-ff"
          />
          <span
            className={s9(c.skipLabel, isListVariant && c.skipLabelSchoonhoven)}
            aria-hidden="true"
          >
            {skipTime}
          </span>
        </button>
      </div>
      <div className={s9(c.audioWrap, isListVariant && c.audioButtonWrapperSchoonhoven)}>
        <button
          className={s9(c.audioButton, isListVariant && c.audioButtonSmall)}
          onClick={isPlaying ? onPauseButtonClick : onPlayButtonClick}
        >
          <Icon
            styleExtend={[c.icon, !isPlaying && c.iconPlay]}
            icon={`${isPlaying ? 'pause' : 'play'}`}
            variant="inverse"
            screenReaderText={playLabel}
          />
        </button>
      </div>
      <div className={s9(c.rw)} data-testid="rw">
        <button onClick={() => onSkip(-skipTime)} className={s9(c.button)}>
          <VisuallyHidden>{rwLabel}</VisuallyHidden>
          <Icon
            styleExtend={[c.skipButton, isListVariant && c.skipButtonSchoonhoven]}
            icon="audio-rw"
          />
          <span
            className={s9(c.skipLabel, isListVariant && c.skipLabelSchoonhoven)}
            aria-hidden="true"
          >
            {skipTime}
          </span>
        </button>
      </div>
    </>
  );
}
