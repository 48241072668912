'use server';
import config from '@haaretz/l-config';
import { isDebugMode } from '@haaretz/s-debug-info/server';
import { headers } from 'next/headers';

import listMap from '../../utils/listMap';

import type { BaseServerActionParams, ListPageType, ListStyle } from '@haaretz/s-types';

interface AbTestListLoaderProps extends BaseServerActionParams {
  listStyle: ListStyle;
  variables: {
    pageType: ListPageType;
    listId: string;
    exclude: string;
  } & (
    | {
        articleId: string;
        sectionId: string;
        tagsIds: string;
      }
    | {
        articleId?: never;
        sectionId?: never;
        tagsIds?: never;
      }
  );
}

const url = `${config.get('brightspot')}/srv/personalization/get-personal-list`;

const defaultResponse = {
  html: null,
};

export default async function loadPersonalList({
  listStyle,
  variables,
}: AbTestListLoaderProps): Promise<{
  html: React.ReactNode;
  url?: string;
  body?: string;
  result?: string;
}> {
  try {
    const headerList = headers();

    const cookieString = headerList.get('cookie');

    const body = JSON.stringify(variables);

    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      body,
      headers: {
        cookie: cookieString || '',
      },
    });

    /* istanbul ignore next */
    const contentLength =
      (res.headers.has('Content-Length') && res.headers.get('Content-Length')) || '';
    const data = contentLength !== '0' ? await res.json() : /* istanbul ignore next */ null;

    if (!data?.items?.length ?? /* istanbul ignore next */ data?.error) {
      if (data?.error) {
        console.error(data.error);
      }

      return defaultResponse;
    }

    const List = listViewExists(listStyle) ? listMap[listStyle] : null;

    if (!List) {
      console.error(`List view ${listStyle} not found`);
      return defaultResponse;
    }

    return {
      html: <List.View {...data} kind="personal" />,
      ...(isDebugMode()
        ? /* istanbul ignore next */ { body, url, result: JSON.stringify(data) }
        : {}),
    };
  } catch (error) {
    console.error(error);

    return defaultResponse;
  }
}

function listViewExists(listStyle: string): listStyle is keyof typeof listMap {
  return listStyle in listMap;
}
