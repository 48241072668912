import { hostname } from '@haaretz/s-consts';

import fetcher from '../../fetcher';

import type { DefaultSearchResponseType, SearchPayloadType } from '../../fetcher/fetcher';

/**
 * This file is almost identical to searchFetcher.tsx, but the domain is different.
 * the path for the fetcher is `/search/a/` instead of `/search/c/`.
 *
 * The searchFetcher.tsx file uses the domain `www.${hostname},` while this file uses `recipes.${hostname},`.
 * We decided to keep them separate because they are used in different parts of the code,
 * but mainly because of the difference in heyday.io path to avoid a mix up.
 *
 * another difference in our payload is that we are adding the `inst` key with a value of `true`.
 */

export interface ResponseType extends DefaultSearchResponseType {
  n: {
    pd: {
      img?: string;
      publicUrl: string;
      title: string;
      url: string;
    };
  }[];
  r: string[];
}

export default async function search(
  args: Pick<SearchPayloadType, 'attribute' | 'q'>
): Promise<ResponseType> {
  return fetcher<ResponseType>('/search/a/', {
    ...args,
    inst: true,
    domain: `recipes.${hostname},`,
  });
}
