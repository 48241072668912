'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import ControlPanel from '@haaretz/s-audio-player/components/AudioControls';
import FirstPlay from '@haaretz/s-audio-player/components/FirstPlay';
import { SliderAudio } from '@haaretz/s-audio-player/components/SliderAudio';
import AudioPlayerTitle from '@haaretz/s-audio-player/components/Title';
import { useAudioPlayer } from '@haaretz/s-audio-player/hooks/useAudioPlayer';
import { playLabel } from '@haaretz/s-audio-player/utils/audioPlayerUtils';
import Icon from '@haaretz/s-icon';
import * as React from 'react';
import s9 from 'style9';

// `c` is short for `classNames`
const c = s9.create({
  grid: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr 1fr',
    gridTemplateColumns: '1fr repeat(3, auto);',
    columnGap: space(4),
    paddingTop: space(4),
    paddingLeft: space(3),
    paddingRight: space(3),
    paddingBottom: space(4),
    ...merge(
      mq({
        from: 's',
        value: {
          gridTemplateRows: '1fr auto auto',
          gridTemplateColumns: '1fr repeat(3, auto);',
          gridColumnStart: 2,
          padding: 0,
        },
      }),
      mq({
        from: 'l',
        value: {
          columnGap: space(8),
        },
      })
    ),
  },
  iconRunButton: {
    minWidth: space(16),
    minHeight: space(16),
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'end',
    justifyContent: 'center',
    borderRadius: radius('circle'),
    backgroundColor: color('primary1000'),

    ':after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    marginInlineStart: space(3),
    ...merge(
      mq({
        from: 'm',
        value: {
          marginInlineStart: space(5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          minWidth: space(18),
          minHeight: space(18),
        },
      })
    ),
  },
  audioplayerTitle: {
    ...typesetter(0),
    ...merge(
      mq({
        from: 's',
        value: {
          minHeight: space(7),
        },
      }),
      mq({
        from: 'l',
        value: {
          gridColumnEnd: 2,
          minHeight: space(6),
        },
      })
    ),
  },
  iconFirstPlay: {
    transform: 'translateX(10%)',
    width: space(8),
    height: space(8),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          width: space(9),
          height: space(9),
        },
      })
    ),
  },
});

export default function AudioPlayerControlPanel() {
  const { wasNotPlayed, onPlayButtonClick, channelName, title } = useAudioPlayer();
  const platform = usePlatform();

  return wasNotPlayed && platform === 'mobile' ? (
    <FirstPlay channelName={channelName} title={title} variant="schoonhoven">
      <button className={s9(c.iconRunButton)} onClick={onPlayButtonClick}>
        <Icon
          icon="play"
          variant="inverse"
          styleExtend={[c.iconFirstPlay]}
          screenReaderText={playLabel}
        />
      </button>
    </FirstPlay>
  ) : (
    <div className={s9(c.grid)} data-testid="audio-player-controls-schoonhoven">
      <AudioPlayerTitle styleExtend={[c.audioplayerTitle]} />
      <SliderAudio variant="schoonhoven" />
      <ControlPanel variant="schoonhoven" />
    </div>
  );
}
