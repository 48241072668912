'use client';

import * as React from 'react';
import { useState, useCallback } from 'react';

import AudioPlayerContext from '../hooks/useAudioPlayer';
import { playbackRate, skipTime as skipTimeDefault } from '../utils/audioPlayerUtils';
import throttleFn from '../utils/throttleFn';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { PodcastEpisodeFragment } from '@haaretz/s-fragments/PodcastEpisode';

export interface AudioPlayerProps {
  fileUrl: string;
  title: string;
  channel?: PodcastEpisodeFragment['channel'];
  image?: ImageFragment;
  children: React.ReactNode;
}

export default function AudioPlayerClient({ fileUrl, title, channel, children }: AudioPlayerProps) {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [wasNotPlayed, setWasNotPlayed] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playbackIndex, setPlaybackIndex] = useState<number>(0);

  const refCallback = useCallback((audioEl: HTMLAudioElement) => {
    setAudio(audioEl);
  }, []);

  const onPlayButtonClick = () => {
    if (!audio) {
      return;
    }
    if (wasNotPlayed) {
      setWasNotPlayed(false);
      audio.src = fileUrl;
      audio.load();
    } else {
      audio.play();
    }
  };

  const onPauseButtonClick = React.useCallback(() => {
    audio?.pause();
  }, [audio]);

  const onPlaying = React.useCallback(() => {
    setIsPlaying(true);
  }, []);

  const onPause = () => {
    setIsPlaying(false);
  };

  const onTimeUpdate = React.useMemo(
    () =>
      audio
        ? throttleFn(() => {
            setCurrentTime(audio.currentTime);
          }, 100)
        : undefined,
    [audio]
  );

  const onLoadedData = React.useCallback(() => {
    audio?.play();
  }, [audio]);

  const onSkip = React.useCallback(
    (skipTime = skipTimeDefault) => {
      if (audio && audio.currentTime) {
        audio.currentTime = audio.currentTime + skipTime;
      }
    },
    [audio]
  );

  const onNextPlayBackRate = React.useCallback(() => {
    if (!audio) return;
    const getNextRateIndex = (currentIndex: number): number => {
      if (currentIndex + 1 < playbackRate.length) {
        return currentIndex + 1;
      }
      return 0;
    };
    audio.playbackRate = playbackRate[getNextRateIndex(playbackIndex)];
    setPlaybackIndex(getNextRateIndex);
  }, [audio, playbackIndex]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={refCallback}
        onLoadedData={onLoadedData}
        onPlaying={onPlaying}
        onTimeUpdate={onTimeUpdate}
        onPause={onPause}
      />
      <AudioPlayerContext.Provider
        value={{
          audio,
          title,
          channelName: channel?.channelName,
          currentTime,
          durationTime: audio?.duration || -1,
          isPlaying,
          wasNotPlayed,
          playbackIndex,

          onSkip,
          onPlayButtonClick,
          onPauseButtonClick,
          onNextPlayBackRate,
        }}
      >
        {children}
      </AudioPlayerContext.Provider>
    </>
  );
}
