import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import HtzLink from '@haaretz/s-htz-link';
import NoSSR from '@haaretz/s-no-ssr';
import * as React from 'react';
import s9 from 'style9';

import type { HeaderNewsListFragment } from '@haaretz/s-fragments/HeaderNewsList';
import type { LiveBlogBreakingNewsFragment } from '@haaretz/s-fragments/LiveBlogBreakingNews';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: -1,
    opacity: 0,
    justifySelf: 'start',
    transitionDuration: '1.25s',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in-out',
    pointerEvents: 'none',
  },
  title: {
    display: 'inline',
  },
  text: {
    ...typesetter(-1),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  current: {
    pointerEvents: 'inherit',
    opacity: 1,
  },
  time: { fontWeight: 700, display: 'inline', marginInlineEnd: space(1) },
});

export interface LiveBlogBreakingNewsItemProps {
  item: LiveBlogBreakingNewsFragment['items'][number] | HeaderNewsListFragment['items'][number];
  isCurrent: boolean;
}

function LiveBlogBreakingNewsItem({ item, isCurrent }: LiveBlogBreakingNewsItemProps) {
  const date = new Date(item.date);
  const time = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <HtzLink className={s9(c.base, isCurrent && c.current)} href={item.url ?? ''}>
      <div className={s9(c.time, c.text)}>{time}</div>
      <div className={s9(c.title, c.text)}>{item.title}</div>
    </HtzLink>
  );
}

export default function LiveBlogBreakingNewsItemNoSSR(props: LiveBlogBreakingNewsItemProps) {
  return (
    <NoSSR>
      <LiveBlogBreakingNewsItem {...props} />
    </NoSSR>
  );
}
